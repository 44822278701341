<template>
  <div :class="darkmode || 'grey lighten-5'" class="pa-4" flat>
    <v-card :color="darkmode ? '#1E1E1E' : 'white'" class="elevation-0 pa-5">
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col cols="12" sm="4">
            <div class="d-flex flex-column">
              <span class="text-center font-weight-bold mb-2">
                {{ $t("aboutSchool.school_logo") }}
              </span>
              <CropImage
                :value="tempImagePotrait"
                :croped-image="value => (tempImagePotrait = value)"
                :isEdit="loading"
                :clearForm="clearFormImage"
                id="potraitImage"
                cropBoxMovable
                typeSize="square"
                @deleteImage="tempImagePotrait = ''"
                @getImageName="value => (imageNamePotrait = value)"
                @cropMove="value => (potraitCropMove = value)"
              />
            </div>
            <div class="d-flex flex-column mt-5">
              <span class="text-center font-weight-bold mb-2">
                {{ $t("aboutSchool.school_logo") }} Landscape
              </span>
              <CropImage
                :value="tempImageLandscape"
                :croped-image="value => (tempImageLandscape = value)"
                :isEdit="loading"
                :clearForm="clearFormImage"
                id="landscapeImage"
                cropBoxMovable
                typeSize="landscape-footer"
                :aspectRatio="3 / 2"
                :initialAspectRatio="3 / 2"
                cropBoxResizable
                @deleteImage="tempImageLandscape = ''"
                @getImageName="value => (imageNameLandscape = value)"
                @cropMove="value => (landscapeCropMove = value)"
              />
            </div>
          </v-col>
          <v-col cols="12" sm="8" class="d-flex flex-column">
            <v-text-field
              v-model="bodyRequest.name"
              :label="$t('aboutSchool.school_name')"
              :disabled="loading"
              :rules="[v => !!v || $t('app.required')]"
              name="school-name"
              clearable
              outlined
              dense
            />
            <v-text-field
              v-model="bodyRequest.phone"
              :label="$t('aboutSchool.school_phone')"
              :disabled="loading"
              :rules="isValid($t('ppdb.rules.phone_valid_rules'), 'number')"
              name="school-phone"
              maxlength="14"
              clearable
              outlined
              dense
              @keypress="isNumberOnly($event)"
            />
            <v-text-field
              v-model="bodyRequest.foundation"
              :label="$t('aboutSchool.foundation')"
              :disabled="loading"
              name="foundation"
              clearable
              outlined
              dense
            />
            <v-text-field
              v-model="bodyRequest.email"
              :disabled="loading"
              :rules="[
                v => !!v || $t('app.required'),
                v => /.+@.+\..+/.test(v) || $t('ppdb.rules.email_valid_rules')
              ]"
              label="Email"
              name="school-email"
              clearable
              outlined
              dense
            />
            <v-textarea
              v-model="bodyRequest.address"
              :label="$t('aboutSchool.school_address')"
              :disabled="loading"
              :rules="[v => !!v || $t('app.required')]"
              auto-grow
              rows="1"
              clearable
              outlined
              dense
            />
            <v-text-field
              v-model="bodyRequest.whatsapp"
              :disabled="loading"
              :rules="
                isValid($t('ppdb.rules.phone_valid_rules'), 'phonenumber')
              "
              label="Whatsapp"
              name="whatsapp"
              clearable
              outlined
              dense
            />
            <v-text-field
              v-model="bodyRequest.twitter"
              :disabled="loading"
              :hint="$t('aboutSchool.twitter')"
              :rules="isValid($t('aboutSchool.msg_url'), 'url')"
              persistent-hint
              label="Twitter"
              name="url-twitter"
              clearable
              outlined
              dense
              class="mb-3"
            />
            <v-text-field
              v-model="bodyRequest.youtube"
              :disabled="loading"
              :hint="$t('aboutSchool.youtube')"
              :rules="isValid($t('aboutSchool.msg_url'), 'url')"
              persistent-hint
              label="Youtube"
              name="url-youtube"
              clearable
              outlined
              dense
              class="mb-3"
            />
            <v-text-field
              v-model="bodyRequest.facebook"
              :disabled="loading"
              :hint="$t('aboutSchool.facebook')"
              :rules="isValid($t('aboutSchool.msg_url'), 'url')"
              persistent-hint
              label="Facebook"
              name="url-facebook"
              clearable
              outlined
              dense
              class="mb-3"
            />
            <v-text-field
              v-model="bodyRequest.instagram"
              :disabled="loading"
              :hint="$t('aboutSchool.instagram')"
              :rules="isValid($t('aboutSchool.msg_url'), 'url')"
              persistent-hint
              label="Instagram"
              name="url-instagram"
              clearable
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-form>
      <v-divider />
      <div class="d-flex justify-end">
        <v-btn
          :loading="loading"
          class="gradient-primary ma-2"
          dark
          depressed
          @click="save"
        >
          <v-icon class="mr-2">mdi-content-save</v-icon>
          {{ $t("app.save") }}
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { list } from "@/api/admin/settings/mainInfo";
import { create } from "@/api/admin/settings/aboutSchool";
import { uploadFileBase64 } from "@/api/admin/admin";
import i18n from "@/i18n";
import { isFormValid } from "@/utils/validate";
import { isNumberOnly } from "@/utils/validate";

export default {
  metaInfo: {
    title: i18n.t("routes.about_school"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    CropImage: () => import("@/components/ImageCrop")
  },
  data() {
    return {
      loading: false,
      clearFormImage: false,
      imageNamePotrait: null,
      imageNameLandscape: null,
      tempImagePotrait: "",
      tempImageLandscape: "",
      potraitCropMove: null,
      landscapeCropMove: null,
      bodyRequest: {
        path_potrait: "",
        path_landscape: "",
        name: "",
        phone: "",
        foundation: "",
        email: "",
        address: "",
        whatsapp: "",
        twitter: "",
        youtube: "",
        facebook: "",
        instagram: ""
      }
    };
  },
  created() {
    this.getData();
  },
  computed: {
    darkmode() {
      return this.$store.getters.g_darkmode;
    }
  },
  methods: {
    isNumberOnly: evt => isNumberOnly(evt),
    isValid: (text, type) => isFormValid(text, type),
    async getData() {
      try {
        const response = await list();
        if (response.data.code) {
          const profileData = response.data.data.about;
          if (profileData) {
            this.bodyRequest = profileData;
            this.tempImagePotrait = this.bodyRequest.path_potrait;
            this.tempImageLandscape = this.bodyRequest.path_landscape;
          }
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    isBase64(str) {
      try {
        return btoa(atob(str)) == str;
      } catch (err) {
        return false;
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let body = JSON.parse(JSON.stringify(this.bodyRequest));

        if (this.imageNamePotrait || this.potraitCropMove) {
          const resPotrait = await uploadFileBase64({
            base64: this.tempImagePotrait,
            path: "school-profile"
          });
          body.path_potrait = resPotrait.data.data.path;
        }

        if (this.imageNameLandscape || this.landscapeCropMove) {
          const resLandscape = await uploadFileBase64({
            base64: this.tempImageLandscape,
            path: "school-profile"
          });
          body.path_landscape = resLandscape.data.data.path;
        }
        console.log(this.landscapeCropMove);

        create(body)
          .then(response => {
            if (response.data.code) {
              this.clearFormImage = true;
              this.snackBar(true, response.data.message);
              location.reload();
            } else {
              this.snackBar(false, response.data.message);
            }
            this.loading = false;
          })
          .catch(() => (this.loading = false));
      }
    }
  }
};
</script>
